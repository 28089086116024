<template>
  <!-- 注意，此頁面設計風格更接近於reservation系列風格，
    故歸類于reservation文件夾下。當案件的狀態(status)小于
    15時，從"@/views/caseRecords/caseRecords.vue"
    頁面中點擊詳情，或是從"@/views/reservation/complete.vue"
    頁面中點擊詳情，會來到此頁面。其他情況下則會前往"@/views/
    caseRecords/caseDetail/index.vue"頁面。 -->
  <div class="container">
    <header :style="{ 'margin-top': statusBarHeight }">
      案件詳情
      <common-questions></common-questions>
    </header>
    <doctor-info :doctorData="doctorData" class="doctor-info"></doctor-info>
    <section class="reservation-info">
      <span>預約時間</span>
      <span>{{ appointmentTime }}</span>
      <span v-if="type === 'video' && isVideoEnd != 2" @click="cancel"
        >取消預約</span
      >
      <span v-else></span>
      <span>預約日期</span>
      <span>{{ appointmentDate }}</span>
    </section>
    <progress-bar
      :type="type"
      :status="status"
      :dateList="dateList"
      class="progress-bar"
    ></progress-bar>
    <section class="notes">
      當你收到你成功預約的通知後，你必須於
      {{ appointmentDate }}
      {{ appointmentTime.split("-")[0] }}
      進入視像診症室。如逾時超過15分鐘，預約將被取消，你的預約費用（HKD
      {{ bookingAmount }}）將不予退還。
    </section>
    <div class="reservation-btn-group">
      <button @click="back">返回</button>

      <router-link
        :to="{
          name: type === 'video' ? 'videoClinic' : 'messageClinic',
          params: { roomNumber },
          query: { loginType: this.$route.query.loginType },
        }"
        custom
        v-slot="{ navigate }"
        v-if="isHaveDoctorLogined == 1 && isVideoEnd != 2"
      >
        <button @click="navigate">進入問診室</button>
      </router-link>
      <router-link
        :to="{
          name: 'reservationPayment',
          params: { id: id },
          query: { loginType: this.$route.query.loginType },
        }"
        custom
        v-slot="{ navigate }"
        v-else-if="status === 0"
      >
        <button @click="navigate">付款</button>
      </router-link>

      <router-link
        :to="{
          name: type === 'video' ? 'videoClinic' : 'messageClinic',
          params: { roomNumber },
          query: { loginType: this.$route.query.loginType },
        }"
        custom
        v-slot="{ navigate }"
        v-else-if="
          roomNumber && isVideoEnd == 1 && isDoTimes == true && status == 10
        "
      >
        <button @click="navigate">進入問診室</button>
      </router-link>
      <button
        v-else
        @click="$message({ type: 'error', message: '問診室已關閉或未開放' })"
      >
        問診室未開放
      </button>
    </div>
  </div>
</template>

<script>
import CommonQuestions from "../../components/commonQuestions.vue";
import DoctorInfo from "../../components/doctorInfo.vue";
import progressBar from "../../components/progressBar.vue";
import { casesDetail, cancelCases, deleteCases } from "@/api";
export default {
  components: {
    progressBar,
    CommonQuestions,
    DoctorInfo,
  },
  data: function () {
    return {
      statusBarHeight: "0px",
      id: 0,
      type: "video",
      status: 0,
      appointmentTime: "",
      appointmentDate: "",
      roomNumber: "",
      isDoTimes: false, //查看时间是否到
      doctorData: {
        name: "",
        specialist: "",
        gender: 0,
        avatar: "",
      },
      dateList: {},
      bookingAmount: "0.00",
      isVideoEnd: 0,
      isHaveDoctorLogined: 0,
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
      // this.$router.replace({ name: "index" });
      // window.close();
    },
    cancel() {
      this.$confirm("你確定要取消預約？", "提示")
        .then(() => {
          let that = this;
          function onSuccess() {
            that.$message({
              type: "success",
              message: "取消成功！",
            });
            that.back();
          }
          function onFailed(err) {
            that.$message({
              type: "error",
              message: err.message,
            });
          }
          // 如果用户还没付款，则应该调用删除预约的接口
          if (this.status === 0) {
            deleteCases(this.id).then(onSuccess).catch(onFailed);
          } else {
            cancelCases({ id: this.id }).then(onSuccess).catch(onFailed);
          }
        })
        .catch(() => {});
    },
  },
  created() {
    if (this.$store.state.statusBarHeight > 0) {
      this.statusBarHeight = this.$store.state.statusBarHeight + "px";
    }
  },
  mounted: function () {
    let id = this.$route.params.id;
    this.id = id;
    casesDetail(id).then((res) => {
      let data = this.$camel(res.data);
      let appointmentTime, appointmentDate;
      if (data.typeId === 1) {
        // appointmentTime = data.appointmentTime.split("-")[0];
        appointmentTime = data.appointmentTime;
        appointmentDate = data.appointmentDate;
        // console.log("kevin===" + data.appointmentTime);
      } else {
        appointmentTime = data.createdAt.split(" ")[1];
        appointmentTime = appointmentTime.split(":").slice(0, 2).join(":");
        appointmentDate = data.createdAt.split(" ")[0];
        // console.log("kevin===" + data.createdAt);
      }
      appointmentDate = `${appointmentDate.split("-")[0]}年${
        appointmentDate.split("-")[1]
      }月${appointmentDate.split("-")[2]}日`;
      this.appointmentDate = appointmentDate;

      this.appointmentTime = appointmentTime;

      //三元表达 设置判断
      new Date() >=
      new Date(
        data.appointmentDate.replace(/-/g, "/") +
          " " +
          data.appointmentTime.split("-")[0]
      )
        ? (this.isDoTimes = true)
        : (this.isDoTimes = false);

      if (data.chatroom) this.roomNumber = data.chatroom.roomNumber;
      this.status = data.status;
      for (let key of Object.keys(this.doctorData)) {
        this.doctorData[key] = data.doctor[key];
      }
      this.type = data.typeId == 1 ? "video" : "text";
      this.bookingAmount = data.bookingAmount;

      let dateList = {
        0: data.createdAt,
        5: data.statusBookingAt,
        10: data.statusSuccessAppointmentAt,
        15: data.statusProcessingAt,
        20: data.statusWaitingReportAt,
        25: data.statusWaitingPaymentAt,
        30: data.statusWaitingDeliveryAt,
        33: data.statusRefundingAt,
        35: data.statusDeliveryAt,
        40: data.statusCompletedAt,
      };
      this.dateList = dateList;

      if (data != null) {
        if (data.chatroom != null) {
          console.log(data);
          this.isVideoEnd = data.chatroom.status; //記錄問診室是否結束狀態
          this.isHaveDoctorLogined = data.chatroom.doctorLogined;
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  background-color: white;
}
.container {
  background-color: $white;
  min-height: 100vh;
  padding: var(--safe-top) 0 var(--safe-bottom);
  position: relative;
  padding-bottom: 60px;
}
header {
  font-size: 24px;
  color: $green-300;
  font-weight: $semi-bold;
  padding: 16px 16px 0 16px;
  @include flexr-between-center;
}
.doctor-info {
  margin-top: 16px;
}
.reservation-info {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr 1fr;
  padding: 0 16px;
  margin-top: 10px;
  span {
    padding: 8px 0;
  }
  span:nth-of-type(1),
  span:nth-of-type(2),
  span:nth-of-type(3) {
    border-bottom: 1px solid $black;
  }
  span:nth-of-type(2),
  span:nth-of-type(5) {
    padding: 8px 16px;
  }
  span:nth-of-type(3) {
    color: $red;
    text-decoration: underline;
  }
}
.notes {
  font-size: 16px;
  padding: 32px;
}
.reservation-btn-group {
  bottom: var(--safe-bottom);
}
</style>