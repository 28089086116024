var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('header',{style:({ 'margin-top': _vm.statusBarHeight })},[_vm._v(" 案件詳情 "),_c('common-questions')],1),_c('doctor-info',{staticClass:"doctor-info",attrs:{"doctorData":_vm.doctorData}}),_c('section',{staticClass:"reservation-info"},[_c('span',[_vm._v("預約時間")]),_c('span',[_vm._v(_vm._s(_vm.appointmentTime))]),(_vm.type === 'video' && _vm.isVideoEnd != 2)?_c('span',{on:{"click":_vm.cancel}},[_vm._v("取消預約")]):_c('span'),_c('span',[_vm._v("預約日期")]),_c('span',[_vm._v(_vm._s(_vm.appointmentDate))])]),_c('progress-bar',{staticClass:"progress-bar",attrs:{"type":_vm.type,"status":_vm.status,"dateList":_vm.dateList}}),_c('section',{staticClass:"notes"},[_vm._v(" 當你收到你成功預約的通知後，你必須於 "+_vm._s(_vm.appointmentDate)+" "+_vm._s(_vm.appointmentTime.split("-")[0])+" 進入視像診症室。如逾時超過15分鐘，預約將被取消，你的預約費用（HKD "+_vm._s(_vm.bookingAmount)+"）將不予退還。 ")]),_c('div',{staticClass:"reservation-btn-group"},[_c('button',{on:{"click":_vm.back}},[_vm._v("返回")]),(_vm.isHaveDoctorLogined == 1 && _vm.isVideoEnd != 2)?_c('router-link',{attrs:{"to":{
        name: _vm.type === 'video' ? 'videoClinic' : 'messageClinic',
        params: { roomNumber: _vm.roomNumber },
        query: { loginType: this.$route.query.loginType },
      },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("進入問診室")])]}}],null,false,335280568)}):(_vm.status === 0)?_c('router-link',{attrs:{"to":{
        name: 'reservationPayment',
        params: { id: _vm.id },
        query: { loginType: this.$route.query.loginType },
      },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("付款")])]}}])}):(
        _vm.roomNumber && _vm.isVideoEnd == 1 && _vm.isDoTimes == true && _vm.status == 10
      )?_c('router-link',{attrs:{"to":{
        name: _vm.type === 'video' ? 'videoClinic' : 'messageClinic',
        params: { roomNumber: _vm.roomNumber },
        query: { loginType: this.$route.query.loginType },
      },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("進入問診室")])]}}])}):_c('button',{on:{"click":function($event){return _vm.$message({ type: 'error', message: '問診室已關閉或未開放' })}}},[_vm._v(" 問診室未開放 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }